import React, { useEffect, useState } from "react";
import ReportTable from "../../../components/Table/ReportTable";

function Reports() {
  const [groupAdminId, setGroupAdminId] = useState(Number);
  const [tableBody, setTableBody] = useState([]);

  function getGroupAdminId() {
    const role_id = parseInt(localStorage.getItem("role_id"), 10);
    const org_id = parseInt(localStorage.getItem("org_id"), 10);
    if ([3, 4, 5, 6].includes(Number(role_id))) {
      setTableBody([
        {
          reportName: "User Yet to Register",
          description: "Users who have been invited but not registered yet",
          viewLink: `reports/org-admin/unregistered-users`,
        },
        {
          reportName: "Registered Users Yet to Start",
          description: "Users who have registered but not started yet",
          viewLink: `reports/org-admin/unstarted-users`,
        },
        {
          reportName: "Users Inactive for more than 'x' No of Days",
          description: "Users who have been inactive for more than 'x' days. ",
          viewLink: `reports/org-admin/inactive-users/3`,
        },
        {
          reportName: "Basic Metrics for days",
          description:
            "Days, Activities & Challenges completed metrics and last active date",
          viewLink: `reports/org-admin/basic-metrics`,
        },
        {
          reportName: "Program Completion",
          description: "Program Completion Report",
          viewLink: `reports/org-admin/program-completion`,
        },
        {
          reportName: "Users Completed Entire Program",
          description: "Users Who have Completed Entire program",
          viewLink: `reports/org-admin/completed-entire-program/${org_id}`,
        },
        {
          reportName: "Users Completed All Learn Activities",
          description: "Users Who have Completed All Learn Activities",
          viewLink: `reports/org-admin/completed-all-learn-activities/${org_id}`,
        },
      ]);
    } else {
      const groupAdminId = parseInt(localStorage.getItem("id"), 10);
      setTableBody([
        {
          reportName: "User Yet to Register",
          description: "Users who have been invited but not registered yet",
          viewLink: `reports/admin/unregistered-users/${groupAdminId}`,
        },
        {
          reportName: "Registered Users Yet to Start",
          description: "Users who have registered but not started yet",
          viewLink: `reports/admin/unstarted-users/${groupAdminId}`,
        },
        {
          reportName: "Users Inactive for more than 'x' No of Days",
          description: "Users who have been inactive for more than 'x' days. ",
          viewLink: `reports/admin/inactive-users/${groupAdminId}/3`,
        },
        {
          reportName: "Basic Metrics for days",
          description:
            "Days, Activities & Challenges completed metrics and last active date",
          viewLink: `reports/admin/basic-metrics/${groupAdminId}`,
        },
        {
          reportName: "Program Completion",
          description: "Program Completion Report",
          viewLink: `reports/admin/program-completion/${groupAdminId}`,
        },
        {
          reportName: "Users Completed Entire Program",
          description: "Users Who have Completed Entire program",
          viewLink: `reports/admin/completed-entire-program/${groupAdminId}`,
        },
        {
          reportName: "Users Completed All Learn Activities",
          description: "Users Who have Completed All Learn Activities",
          viewLink: `reports/amdin/completed-all-learn-activities/${groupAdminId}`,
        },
      ]);
    }
  }
  useEffect(() => {
    getGroupAdminId();
  }, []);

  const tHead = ["Report", "Description", "Actions"];
  const tBody = tableBody;

  return (
    <div>
      <ReportTable header={tHead} body={tBody} />
    </div>
  );
}

export default Reports;
